.ant-main-menu {
  padding-left: max(calc(calc(100% - 1055px) / 2), 0px) !important;
}

.ant-main-menu > li {
  width: 120px !important;
  text-align: center !important;
}

.ant-main-menu > li:first-child {
  width: 100px !important;
}

.ant-main-menu > li:nth-last-child(2) {
  width: 110px !important;
}

.normal {
  --h1-width: 210px;
}

.thin {
  --h1-width: 160px;
}

.title > div.img {
  position: absolute;
  width: 60px;
  height: 70px;
  margin-left: calc(50% - calc(var(--h1-width) + 60px) / 2);
  margin-top: 7px;
  cursor: pointer;
}