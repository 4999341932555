:root {
  --mainpage-background-color: #DEF9FFFF;
  --normal-font-color: #000000;
  --normal-photo-background-color: #FFFFFF;
  --normal-link-color: #0000EE;
  --footer-backgroud-color: #00000011;
  --ant-pagination-color: #FFFFFF88;
}

@media (prefers-color-scheme: dark) {
  :root {
    --mainpage-background-color: #012D33FF;
    --normal-font-color: #FFFFFF;
    --normal-photo-background-color: #141414;
    --normal-link-color: #00BBFF;
    --footer-backgroud-color: #FFFFFF15;
    --ant-pagination-color: #14141457;
  }
}

html {
  background-color: #DEF9FFFF;
  background-color: var(--mainpage-background-color);
}

ul {
  margin-bottom: 0;
}

.ant-pagination-item-active {
  background-color: #FFFFFF88 !important;
  background-color: var(--ant-pagination-color) !important;
}