footer[data-v-910e75e4] {
  text-align: center;
  background-color: var(--footer-backgroud-color);
  margin-top: 10px;
  width: 100%;
  height: 153px;
}
footer > p[data-v-910e75e4] {
  color: var(--normal-font-color);
}
footer > p > a[data-v-910e75e4] {
  color: var(--normal-link-color);
  text-decoration: underline;
}
footer > p[data-v-910e75e4]:first-child {
  margin-top: 15px;
}
.fixed[data-v-910e75e4] {
  position: fixed;
  bottom: 0;
}
.absolute[data-v-910e75e4] {
  position: absolute;
}
.footer[data-v-910e75e4] {
  margin-top: 10px;
  width: 100%;
  height: 153px;
}