.home-photos[data-v-5f3b41ae],
.home-buttons[data-v-5f3b41ae] {
  position: absolute;
  height: 540px;
  width: 100%;
}
.home-photos-phone[data-v-5f3b41ae],
.home-buttons-phone[data-v-5f3b41ae] {
  height: 260px;
}
.home-buttons[data-v-5f3b41ae] {
  position: relative;
  width: 96%;
  margin-left: 2%;
  margin-top: 10px;
}
.photos[data-v-5f3b41ae] {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.background[data-v-5f3b41ae] {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #1279BE5A;
  z-index: 1;
}
.context[data-v-5f3b41ae] {
  position: absolute;
  width: calc(100% - 80px);
  height: 460px;
  color: #FFFFFF;
  z-index: 2;
  margin: 40px;
  -webkit-user-select: none;
  user-select: none;
}
.title[data-v-5f3b41ae] {
  text-align: center;
  margin-top: 15px;
}
.title > h1[data-v-5f3b41ae] {
  margin: -5px 0 0;
  text-align: center;
  font-size: 60px;
  display: inline-block;
  vertical-align: middle;
  font-weight: bold;
}
.title > img[data-v-5f3b41ae] {
  width: 60px;
  margin-right: 5px;
  display: inline-block;
  vertical-align: middle;
}
.context-phone[data-v-5f3b41ae] {
  margin: 20px;
  height: 220px;
  width: calc(100% - 40px);
}
.context-phone > .title[data-v-5f3b41ae] {
  margin-top: 0;
}
.context-phone > .title > h1[data-v-5f3b41ae] {
  font-size: 30px;
  margin-top: 0;
}
.context-phone > .title > img[data-v-5f3b41ae] {
  width: 30px;
}
.context > p[data-v-5f3b41ae] {
  text-align: center;
  font-size: 25px;
  margin: 25px 0;
  -webkit-user-select: none;
  user-select: none;
}
.context-phone > p[data-v-5f3b41ae] {
  font-size: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.eachline[data-v-5f3b41ae] {
  width: 100%;
  height: 80px;
  margin: 30px 0 30px 0;
}
._25[data-v-5f3b41ae] {
  width: 25%;
}
._25[data-v-5f3b41ae]:first-child {
  margin-left: calc(12.5% - 10px);
}
._40[data-v-5f3b41ae] {
  width: 40%;
}
._40[data-v-5f3b41ae]:first-child {
  margin-left: calc(10% - 5px);
}
.button[data-v-5f3b41ae] {
  text-align: center;
  border-radius: 5px;
  font-size: 30px;
  float: left;
  height: 60px;
  margin: 0 5px;
  cursor: pointer;
}
.button > a[data-v-5f3b41ae] {
  top: 11px;
  position: relative;
  text-decoration: none;
  color: #FFFFFF;
  text-align: center;
  font-weight: 700;
  -webkit-user-select: none;
  user-select: none;
}
.orange > .button[data-v-5f3b41ae] {
  background: linear-gradient(135deg, #FCB900, #FF6900);
}
.green > .button[data-v-5f3b41ae] {
  background: linear-gradient(135deg, #CAF880, #71CE7E);
}
.luminous > .button[data-v-5f3b41ae]:first-child {
  background: linear-gradient(135deg, #4158D0, #C751C0 50%, #FFCB70);
}
.luminous > .button[data-v-5f3b41ae]:last-child {
  background: linear-gradient(135deg, #FFCB70, #C751C0 50%, #4158D0);
}
.context-phone > .buttons[data-v-5f3b41ae] {
  height: 162px;
}
.context-phone > .buttons > .eachline[data-v-5f3b41ae] {
  margin: 10px 0 10px 0;
  height: 50px;
}
.context-phone > .buttons > .eachline > .button[data-v-5f3b41ae] {
  height: 40px;
  font-size: 15px;
}
.context-phone > .buttons > .eachline > ._25[data-v-5f3b41ae] {
  width: calc(33.3% - 6.66px);
}
.context-phone > .buttons > .eachline > ._25[data-v-5f3b41ae]:first-child {
  margin-left: 0;
}
.context-phone > .buttons > .eachline > ._25[data-v-5f3b41ae]:last-child {
  margin-right: 0;
}
.context-phone > .buttons > .eachline > ._40[data-v-5f3b41ae] {
  width: calc(50% - 5px);
}
.context-phone > .buttons > .eachline > ._40[data-v-5f3b41ae]:first-child {
  margin-left: 0;
}
.context-phone > .buttons > .eachline > ._40[data-v-5f3b41ae]:first-child {
  margin-left: 0;
}
.context-phone > .buttons > .eachline > ._40[data-v-5f3b41ae]:last-child {
  margin-right: 0;
}