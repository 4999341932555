.photo-item[data-v-f89bb569] {
  cursor: pointer;
  border-radius: 5px;
  background-color: var(--normal-photo-background-color);
  transition: -webkit-transform .5s;
  transition: transform .5s;
  transition: transform .5s, -webkit-transform .5s;
  float: left;
  margin: 10px;
  width: max(45vw, 293px);
  height: max(30vw, 195px);
}
.photo-item[data-v-f89bb569]:hover {
  -webkit-transform: scale(1.07, 1.07);
          transform: scale(1.07, 1.07);
}
.center[data-v-f89bb569]:last-child {
  margin-left: calc(50% - 22.5vw - 5px);
}
.photo-item > img[data-v-f89bb569] {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 100%;
  height: 70%;
  object-fit: cover;
}
.photo-item > div[data-v-f89bb569] {
  padding: 10px 15px 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 100%;
  height: 30%;
  background-color: var(--normal-photo-background-color);
}
.photo-item > div > p[data-v-f89bb569] {
  color: var(--normal-font-color);
  font-size: 2vw;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  -webkit-user-select: none;
  user-select: none;
}
.photo-item-phone[data-v-f89bb569] {
  width: 96vw;
  height: 60vw;
  font-size: 40px;
  margin: 5px 0 5px 2vw;
}
.photo-item-phone[data-v-f89bb569]:hover {
  -webkit-transform: none;
          transform: none;
}
.photo-item-phone > div > p[data-v-f89bb569] {
  font-size: 5vw;
}