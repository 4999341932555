.title[data-v-3a02de2a] {
  width: 100%;
  background-color: var(--normal-photo-background-color);
  height: 80px;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
}
.title > h1[data-v-3a02de2a] {
  position: absolute;
  padding-left: 10px;
  color: var(--normal-font-color);
  text-align: center;
  font-size: 50px;
  font-weight: bold;
  margin-left: calc(50% - calc(var(--h1-width) + 60px) / 2 + 60px);
  margin-top: 9px;
}
.dropdown[data-v-3a02de2a] {
  position: absolute;
  top: 24px;
  left: 10px;
}